<template>
    
    <!-- Structure -->
    <v-container fluid>
        
        <!-- Toolbar -->
        <v-text-field
            clearable
            dense
            hide-details
            label="Rechercher une carte"
            prepend-icon="search"
            single-line
            v-model="sc"
            @keydown.enter="search">
        </v-text-field>

        <!-- Collection - Affichage liste -->
        <v-card>
            <v-card-text>
                <v-row>{{ test }}</v-row>
                <v-row :key="i.identifiers.scryfallId" v-for="i in i" @click="display(i)">
                    <v-col>
                        <div class="picture" :style="{'background-image': `url(${picture(i.identifiers.scryfallId,'front','jpg','small')})`}"></div>
                    </v-col>
                    <v-col>{{ i.identifiers.scryfallId }}</v-col>
                    <v-col>{{ i.name }}</v-col>
                    <v-col>{{ i.foreignData.filter(l => {return (l.language ? l.language === "French" : false);})[0].name }}</v-col>
                </v-row>
            </v-card-text>
        </v-card>

    </v-container>
</template>

<script>

import standard from "../assets/data/mtg/Standard.json";

var error_alert_timer;
var message_alert_timer;
var warning_alert_timer;

export default {
    computed: {
    },
    created() {
    },
  data() {
    return {
        cc: null,
        ccp: null,
        error: '',
        error_alert: false,
        i: [],
        message: '',
        message_alert: false,
        sc: '',
        sr: [],
        test: null,
        warning: '',
        warning_alert: false
    };
  },
  methods: {
    alert_display(alert_type){
        switch(alert_type){
            case 'error' :
                this.error_alert = true;
                error_alert_timer = setTimeout(() => (this.error_alert = false), 5000);
                break;
            case 'warning' :
                this.warning_alert = true;
                warning_alert_timer = setTimeout(() => (this.warning_alert = false), 5000);
                break;
            case 'message' :
                this.message_alert = true;
                message_alert_timer = setTimeout(() => (this.message_alert = false), 5000);
                break;
        }
    },
    clear_alert_display(alert_type){
        switch(alert_type){
            case 'error' :
                this.error_alert = false;
                clearTimeout(error_alert_timer);
                break;
            case 'warning' :
                this.warning_alert = false;
                clearTimeout(warning_alert_timer);
                break;
            case 'message' :
                this.message_alert = false;
                clearTimeout(message_alert_timer);
                break;
        }
    },
    picture(identifier, face, format, type) {
        const fa = face; // 'front'
        const t = type; // 'large', 'small'
        const fo = format; // '.jpg'
        const id = identifier;
        const d1 = id.charAt(0);
        const d2 = id.charAt(1);
        return `https://cards.scryfall.io/${t}/${fa}/${d1}/${d2}/${id}.${fo}`;
    },
    display(c) {
        console.log(c);
    },
    search(e){
        this.i = [];
        this.sr = [];
        if (this.sc && (this.sc.length > 4 || e.key)) {
            const f = standard.data;
            f ? this.test = typeof (f) : this.test = "KO";
            for (var s in f) {
                for (var c in f[s].cards) {
                    for (var l in f[s].cards[c].foreignData) {
                        if (f[s].cards[c].foreignData[l].name.toLowerCase().indexOf(this.sc) != -1 && 
                        this.sr.filter(card => {return card.identifiers.scryfallId === f[s].cards[c].identifiers.scryfallId}).length == 0) {
                            this.sr.push(f[s].cards[c]);
                        }
                    }
                }
            }
            this.i = this.sr;
        }
    },
  },
  name: "mtg",
  watch: {
    sc: 'search',
},
};
</script>

<style>

.picture {
    background-size: contain;
    height: 50px;
}

</style>